import React, { useState } from 'react'
import { BsFiletypeDoc, BsX } from 'react-icons/bs'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import { useDispatch, useSelector } from 'react-redux';
import { setAttachment, setIsAttachment } from '../../../../redux/features/chat/chatSlice';

export default function Attachments() {
    const dispatch = useDispatch();
    const { attachments, imageMimeTypes } = useSelector((state) => state.chat);
    const [thumbsSwiper, ] = useState(null);
    const CheckMimeType = (fileType) => {
        console.log('fileType', imageMimeTypes.includes(fileType))
        return imageMimeTypes.includes(fileType);
    }
    const hanldeAttachmentClose = () => {
        dispatch(setIsAttachment(false));
        dispatch(setAttachment([]));
    };
    return (
        <div className='message-user-attachments'>
            <div className="attachment-header">
                <h5>Attachments</h5>
                <button className='btn' type="button" onClick={hanldeAttachmentClose}>
                    <BsX size={20} />
                </button>
            </div>
            <div className="attachment-body">
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                    }}
                    spaceBetween={10}
                    slidesPerView={2}
                    // navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                >
                    {attachments.map((e, i) => (
                        <SwiperSlide key={i}>
                            {/* <div className="attachment-card"> */}
                                {CheckMimeType(e.type) ?
                                   
                                            <img className='img-fluid' style={{ height: '200px' }} src={URL.createObjectURL(e)} alt="img" />
                                      
                                    :
                                    <>
                                        <BsFiletypeDoc size={30} />
                                        <small>{e.name}</small>
                                    </>
                                }
                            {/* </div> */}
                        </SwiperSlide>
                    ))}
                </Swiper>
                {/* pagination */}
                {/* <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                >
                    {attachments.map((e) => (
                        <SwiperSlide>
                            <div className="attachment-card">
                                {CheckMimeType(e.type) ?
                                    <img className='img-fluid' style={{ height: '80px' }} src={URL.createObjectURL(e)} alt="img" />
                                    :
                                    <>
                                        <BsFiletypeDoc size={30} />
                                        <small>{e.name}</small>
                                    </>
                                }
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper> */}
                {/* pagination */}
            </div>
        </div>
    )
}
