import React from 'react';
import { useSelector } from 'react-redux';
import ChatBody from './ChatBodyLayout/Body';
import ChatFooter from './ChatBodyLayout/Footer';
import ChatHeader from './ChatBodyLayout/Header';

export default function UserChat({ sendMessage }) {
    const { selectedChat } = useSelector((state) => state.chat);
    
    return (
        <>
            <div className="content-chat-message-user "  data-username={selectedChat?.full_name} >
                <ChatHeader />
                <ChatBody />
                <ChatFooter
                sendMessage={sendMessage}
                />
                
     
            </div>
        </>
    )
}
