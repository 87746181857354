import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chats: [],
    selectedChat: null,
    chatMessages: [],
    audio: null,
    audioUrl: null,
    isAttachment: false,
    attachments: [],
    imageMimeTypes: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
        "image/svg+xml",
        "image/vnd.microsoft.icon",
        // Add more as needed
    ]
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setChats: (state, action) => {
            state.chats = action.payload;
        },
        setSelectedChat: (state, action) => {
            state.selectedChat = action.payload;
        },
        setMessages: (state, action) => {
            state.chatMessages = action.payload;
        },
        updateMessages: (state, action) => {
            state.chatMessages.push(action.payload);
        },
        setAudio: (state, action) => {
            state.audio = action.payload;
            state.isAttachment = false;
            state.attachments = []
        },
        setAudioUrl: (state, action) => {
            state.audioUrl = action.payload;
        },
        setIsAttachment: (state, action) => {
            state.isAttachment = action.payload;
        },
        setAttachment: (state, action) => {
            console.log('action.payload', action.payload)
            if (Array.isArray(action.payload)) {
                // remove all items
                state.attachments = action.payload;
            } else {
                state.attachments[0] = action.payload;
                // state.attachments.push(action.payload);
            }
            state.audio = null;
            state.audioUrl = null;
        },
    },
});

export const {
    setChats,
    setMessages,
    updateMessages,
    setSelectedChat,
    setAudio,
    setAudioUrl,
    setIsAttachment,
    setAttachment,
} = chatSlice.actions;


export default chatSlice.reducer;