// import logo from './logo.svg';
import './App.css';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Chat from './pages/chat';
import Sidbar from './components/sidbar/Sidbar';

function App() {
  return (
  <>
   <Router>
        <Routes>
        
        <Route exact path="/chat" element={<Chat />}/>
        <Route exact path="/sidbar" element={<Sidbar />}/>

        
        </Routes>
      </Router>
  </>
  );
}

export default App;
