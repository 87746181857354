import React, { useEffect, useRef } from 'react';
import { BsSendFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { setAttachment, setAudio, setAudioUrl, setIsAttachment } from '../../../../redux/features/chat/chatSlice';
import AttachmentAction from '../../modules/attachments/AttachmentsAction';
// import AudioRecorder from '../../modules/audio';

export default function ChatFooter({ sendMessage }) {
    console.log('run footer')
    const dispatch = useDispatch();
    const messageInput = useRef(null);
    const { audio, audioUrl, isAttachment, selectedChat } = useSelector((state) => state.chat);

    useEffect(() => {
        if (audio) {
            convertAudio(audio);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio])

    const convertAudio = async (audio) => {
        const audioData = new Uint8Array(audio);
        // Convert the Uint8Array to a Base64-encoded string
        function uint8ArrayToBase64(uint8Array) {
            let binary = '';
            for (let i = 0; i < uint8Array.length; i++) {
                binary += String.fromCharCode(uint8Array[i]);
            }
            return btoa(binary);
        }
        const base64String = uint8ArrayToBase64(audioData);
        dispatch(setAudioUrl(`data:audio/mpeg;base64,${base64String}`));
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            send();
        }
    }

    // const onEmojiChange = (e) => {
    //     setMessageInput((prev) => prev + e.emoji)
    //     console.log(e);
    // }

    const OpenChatInput = () => {
        dispatch(setAudio(null));
        dispatch(setAudioUrl(null));
    }

    const onAttachmentChange = (e) => {
        if (e.target.files.length > 0) {
            dispatch(setAttachment(e.target.files[0]))
            // Array(e.target.files.length).fill().map((a, i) => dispatch(setAttachment(e.target.files[i])))
            dispatch(setIsAttachment(true));
        }
    };

    const send = () => {
        console.log("send",messageInput.current.value)
        sendMessage(messageInput.current.value);
        messageInput.current.value = null;
    }

    return (<>
        
            <div className="footer-chat-message-user">
                <AttachmentAction onChange={onAttachmentChange} />
                <div className="message-user-send">
                    {audioUrl && <>
                        <audio src={audioUrl} controls></audio>
                        <span className='close' onClick={OpenChatInput}>x</span>
                    </>
                    }
                    <input type="text" placeholder="Aa" ref={messageInput} onKeyDown={handleKeyDown} hidden={audioUrl} disabled={isAttachment} />
                </div>
                <div className='d-flex flex-row'>
                    {/* <Emoji onChange={onEmojiChange}/> */}
                    {/* <AudioRecorder /> */}
                    <button  type="button" onClick={send} disabled={!messageInput && !audio}>
                        <BsSendFill />
                    </button>
                </div>
            </div>
    </>
    )
}
