import { createSlice } from "@reduxjs/toolkit";
import shortsound from "../../../components/notifications/very_short_notif.mp3"
import shortnoti from "../../../components/notifications/notification.mp3"

const initialState = {
  loader: false,
};

const loaderSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    startLoader: (state, action) => {
      state.loader = true;
    },
    stopLoader: (state, action) => {
      state.loader = false;
    },
    shortNotiSound: (state, action) => {
      const audio = new Audio(shortsound);
      console.log("shortsound",audio)
      audio.play();
    },
    notiSound: (state, action) => {
      const audio = new Audio(shortnoti);
      console.log("shortnoti",audio)

      audio.play();
    }
  },
});

export const { startLoader, stopLoader, shortNotiSound, notiSound } = loaderSlice.actions;

export default loaderSlice.reducer;