import React, { useEffect, useRef, useState } from 'react';
import { BsDownload, BsFiletypeDoc } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { BaseUrl } from '../../../../baseurl/BaseUrl';
import Attachments from '../../modules/attachments/Attachment';
import { getApiWithToken } from '../../../Helper/helper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import doctor from "../../doctor.png"
// import Header from "./Header"
import chaticon from "../../../chat-icon.png"
export default function ChatBody() {
    console.log('run body')
    const lastMessageRef = useRef(null);
    const navigate = useNavigate();

    // const loginUser = JSON.parse(localStorage.getItem('user'));
    const [token] = useState(localStorage.getItem("access_token"));

    const { isAttachment, selectedChat, chatMessages } = useSelector((state) => state.chat);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [profile, setProfile] = useState([]);
    const [loginUser, setloginUser] = useState([]);
   

    useEffect(() => {
        getProfile()
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });

        // attachment send issue
        setTimeout(() => {
            lastMessageRef.current?.scrollIntoView();
        }, 1000);
        // attachment send issue
    }, [chatMessages]);




    const getProfile = () => {
        // dispatch(startLoader());
        getApiWithToken(
            `${BaseUrl.baseUrl}chats/user/specific_user_detail/`,
            "",
            token
        ).then(({ data }) => {
            //   dispatch(stopLoader());
            const Data = data.data;
            if (data.status) {
                console.log("Data profile", Data)
                setloginUser(Data)
                setFname(Data.first_name)
                setLname(Data.last_name)
                setProfile([Data.profile])
                // dispatch(setMessages(Data));
            } else {
                toast.warn(Data.message);
            }
        }).catch((err) => {
            //   dispatch(stopLoader());
            if (err.message === 'Unauthorized') {
                navigate('/login');
            }
            toast.warn(err.message);
        });
    };

    
    return (<>
        <div className="body-chat-message-user">
            {isAttachment ?
                <Attachments />
                :
                chatMessages.length > 0 ?
                    <>
                        {chatMessages.map((e, i) => (
                         
                            e.sender == loginUser.id ?
                                <div className="message-user-right" key={i}>
                                    <div className="message-user-right-img">
                                        <p className="mt-0 mb-0">
                                            <strong>{loginUser.first_name + ' ' + loginUser.last_name}</strong>
                                        </p>

                                        {profile.map((v, i) => {
console.log("profile body", v.profile_pic)
                                            return <img
                                                src={BaseUrl.imgUrl + v.profile_pic}
                                                //  src={doctor}
                                                alt="profile"
                                                loading='lazy'
                                            />
                                        })}
                                    </div>
                                    <div className="message-user-right-text">
                                        <MessageType message={e} />
                                    </div>
                                </div>
                                :
                                <div className="message-user-left" key={i}>
                                    <div className={"message-user-left-img"}>
                                        <img
                                            src={BaseUrl.imgUrl + selectedChat.profile}
                                            alt=""
                                            loading='lazy'
                                        />
                                        <p className="mt-0 mb-0 text-capitalize">
                                            <strong>{selectedChat.full_name}</strong>
                                        </p>
                                        {/* <small>mié 17:59</small> */}
                                    </div>
                                    <div className="message-user-left-text">
                                        <MessageType message={e} />
                                    </div>
                                </div>
                        ))}
                        < div ref={lastMessageRef} />
                    </>
                    :
                    <div className='d-flex justify-content-center align-items-center'>
                        <img src={chaticon} alt='chat_icon' />
                        <h3>Get Started Chat</h3>
                    </div>
            }
        </div>



    </>
    )
}


const token = localStorage.getItem("access_token");


export const MessageType = ({ message }) => {
    // console.log("messsage check",message)
    const convertAudio = (audio) => {
        const audioData = new Uint8Array(audio.split(','));
        // Convert the Uint8Array to a Base64-encoded string
        function uint8ArrayToBase64(uint8Array) {
            let binary = '';
            for (let i = 0; i < uint8Array.length; i++) {
                binary += String.fromCharCode(uint8Array[i]);
            }
            return btoa(binary);
        }
        const base64String = uint8ArrayToBase64(audioData);

        return `data:audio/mpeg;base64,${base64String}`;
    };

    const openImage = (file) => {
        window.open(file);
    }

  
    const handleDownload = (id) => {
        console.log("message",id)
        // dispatch(startLoader());
        getApiWithToken(
            `${BaseUrl.baseUrl}chats/user/file_download/?fileid=${id}`,
            "",
            token
        ).then(({ data }) => {
            console.log("Data pdf", data); 
           
            const absoluteUrl =  BaseUrl.fileurl+data;

            // const absoluteUrl = `http://192.168.0.128:8000${data}`;
            console.log("absoluteUrl", absoluteUrl);  
            window.open(absoluteUrl, '_blank');

            //   dispatch(stopLoader());
            
            if (data) {
                // const absoluteUrl = `http://192.168.0.128:8000${data}`;
                // window.open(absoluteUrl, '_blank');
                // console.log("Data pdf", absoluteUrl);  
            } else {
                toast.warn(data.message);
            }
        }).catch((err) => {
            //   dispatch(stopLoader());
            if (err.message === 'Unauthorized') {
                // navigate('/login');
            }
            toast.warn(err.message);
        });
    };

    // const handleDownload = (url, fileName) => {
    //     console.log("url filename",url, fileName)
    //     axios.get(url, {
    //         responseType: 'blob',
    //     })
    //         .then((res) => {
    //             // res.data //Blog object
    //             console.log("res",res)
    //             const fileurl = URL.createObjectURL(res.data)
    //             const link = document.createElement('a');
    //             link.download = fileName;
    //             link.href = fileurl;
    //             link.click();
    //         })
    // }

    let msg = null;
    if (message.message_type === 'audio') {
        msg = (
            <audio src={convertAudio(message.audio_bytes)} controls></audio>
        )
    } else if (message.message_type === 'image') {
        msg = (
            <img className='img-fluid' src={message.file} style={{ cursor: 'pointer' }} alt='attachment' onClick={() => openImage(message.file)} />
        )
    } else if (message.message_type === 'docs') {
        msg = (<>
            <span>
                <BsFiletypeDoc size={30} />
                <span> {message.filename && message.filename.split('/')[1]} </span>
            </span>
            {/* <span className='download-btn' onClick={() => handleDownload(BaseUrl.fileurl+message.file, message?.filename?.split('/')[1])}>
                <BsDownload size={18} />
            </span> */}

            <span className='download-btn' onClick={() => handleDownload(message.id)}>
                <BsDownload size={18} />
            </span>
        </>
        )
    } else if (message.message_type === 'text') {
        msg = (
            <strong>{message.message}</strong>
        )
    }

    return msg;
}