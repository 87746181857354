import React from 'react'
import { useSelector } from 'react-redux';
// import doctor from "../../doctor.png"
import { BaseUrl } from '../../../../baseurl/BaseUrl';

export default function ChatHeader() {
    console.log('run header')
    const { selectedChat } = useSelector((state) => state.chat);
    console.log("selectedChat",selectedChat)

    return (
        <>
            {/* <div className="head-chat-message-user">
                <img
                    src={doctor}
                    alt="profile"
                    loading='lazy'
                />
                <div className="message-user-profile">
                    <strong className="mt-0 mb-0 text-capitalize">
                        safdar
                    </strong>
                    <small>
                        <p className="online mt-0 mb-0" />
                        Online
                    </small>
                </div>
            </div> */}


            {
                selectedChat ?
                    <div className="head-chat-message-user" >
                        <img
                            src={BaseUrl.imgUrl + selectedChat.profile}
                            alt="profile"
                            loading='lazy'
                        />
                        <div className="message-user-profile">
                            <strong className="mt-0 mb-0 text-capitalize">
                                {selectedChat.full_name}
                            </strong>
                            <small>
                                <p className="online mt-0 mb-0" />
                                Online
                            </small>
                        </div>
                    </div>
                    :
                    null
                // <h4 className='w-100 text-center text-white'>Get Started Chat</h4>
            }

            {/* // <h4 className='w-100 text-center text-white'>Get Started Chat</h4> */}

        </>
    )
}
