import React, { useRef } from 'react'
import { BsPaperclip } from 'react-icons/bs'

export default function AttachmentAction({ onChange }) {
    const ref = useRef(null);

    const handleClick = () => {
        ref.current.click();
    }
    return (
        <>
            <button type="button" onClick={handleClick}>
                <BsPaperclip />
            </button>
            <input ref={ref} type="file" onChange={onChange} accept='*' hidden />
        </>
    )
}
