export const BaseUrl = {
    baseUrl: "https://billionaireclubcollc.com/",
    fileurl: "https://billionaireclubcollc.com",
    // baseUrlNew: "http://192.168.0.123:8003/",
    imgUrl: "https://billionaireclubcollc.com",
    socketUrl: "https://billionaireclubcollc.com/",
    socketIp: "wss://api.billionaireclubcollc.com/",
  }



  // export const BaseUrl = {
  //   baseUrl: "http://127.0.0.1:8001/",
  //   fileurl: "http://127.0.0.1:8001",
  //   // baseUrlNew: "http://192.168.0.123:8003/",
  //   imgUrl: "http://127.0.0.1:8001",
  //   socketUrl: "http://127.0.0.1:8001/",
  //   socketIp: "ws://0.0.0.0:9001/",
  // }
  