import { configureStore } from "@reduxjs/toolkit";
import utilsSlice from "./redux/features/utils/utilsSlice";
import chatSlice from "./redux/features/chat/chatSlice";

const store = configureStore({
  reducer: {
    utils: utilsSlice,
    chat: chatSlice,
  },
});

export default store;