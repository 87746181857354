import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PostApi, getApiWithToken } from '../../components/Helper/helper';
import { BaseUrl } from '../../baseurl/BaseUrl';
// import Header from '../../components/Header';
import ChatUserList from '../../components/chat/ChatUserList';
import UserChat from '../../components/chat/UserChat';
import { setAttachment, setAudio, setAudioUrl, setChats, setIsAttachment, setMessages, setSelectedChat, updateMessages } from '../../redux/features/chat/chatSlice';
import { notiSound, shortNotiSound, startLoader, stopLoader } from '../../redux/features/utils/utilsSlice';
import './chat.css';
import Sidbar from '../../components/sidbar/Sidbar';
function Chat() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("access_token"));
  // const [loginUser] = useState(JSON.parse(localStorage.getItem("user")));
  const { audio, attachments, selectedChat, imageMimeTypes } = useSelector((state) => state.chat);
  const [loginUser, setloginUser] = useState([]);
  const [chatSockets, setChatSockets] = useState({});

  console.log('run chat', loginUser)
  console.log('token chat', token)

  const [socket, setSocket] = useState(null);


  useEffect(() => {
    getProfile()
    getChats();
 
    // setTimeout(() => {
    // }, 10000);

    const userChats = document.querySelectorAll('.user-chat');
    const chatMessages = document.querySelectorAll('.content-chat-message-user');

    userChats.forEach((userChat) => {
      userChat.addEventListener('click', () => {
        const selectedUsername = userChat.getAttribute('data-username');

        chatMessages.forEach((chatMessage) => {
          const messageUsername = chatMessage.getAttribute('data-username');

          if (messageUsername === selectedUsername) {
            chatMessage.classList.add('active');
          } else {
            chatMessage.classList.remove('active');
          }
        });

        userChats.forEach((chat) => {
          chat.classList.remove('active');
        });
        userChat.classList.add('active');
      });
    });

    // Activar el primer elemento user-chat inicialmente
    userChats[0]?.classList?.add('active');
    chatMessages[0]?.classList?.add('active');



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getProfile = () => {
    // dispatch(startLoader());
    getApiWithToken(
      `${BaseUrl.baseUrl}chats/user/specific_user_detail/`,
      "",
      token
    ).then(({ data }) => {
      //   dispatch(stopLoader());
      const Data = data.data;
      if (data.status) {
        console.log("Data profile main", Data)
        setloginUser(Data)
        privateRoom(Data?.id);

        ;
      } else {
        toast.warn(Data.message);
      }
    }).catch((err) => {
      //   dispatch(stopLoader());
      if (err.message === 'Unauthorized') {
        navigate('/login');
      }
      toast.warn(err.message);
    });
  };


  const privateRoom = (id) => {
    let socket = new WebSocket(`${BaseUrl.socketIp}chating_conversation/personal_room__${id}`);
    setSocket(socket)
    socket.onopen = function (event) {
      console.log("WebSocket connection established conversation.");
    };

    socket.onmessage = function (event) {
      const data = JSON.parse(event.data);
      console.log("Received message from server:", data);
      dispatch(notiSound());
      dispatch(setChats(data));
    };

    socket.onclose = function (event) {
      console.log("WebSocket connection closed.");
    };
  }


  const getChats = () => {
    getApiWithToken(
      `${BaseUrl.baseUrl}chats/user/specific_user_list`,
      "",
      token
    ).then(({ data }) => {
      const Data = data.data;
      // console.log('Data', Data);
      if (data.status) {
        dispatch(setChats(Data));
      } else {
        toast.warn(Data.message);
      }
    }).catch((err) => {
      // if (err.message === 'Unauthorized') {
      //   navigate('/login');
      // }
      toast.warn(err.message);
    });
  };


  const getMessages = (id) => {
    dispatch(startLoader());
    getApiWithToken(
      `${BaseUrl.baseUrl}chats/get_message/?receiver=${id}`,
      "",
      token
    ).then(({ data }) => {
      dispatch(stopLoader());
      const Data = data.data;
      if (data.status) {
        dispatch(setMessages(Data));
      } else {
        toast.warn(Data.message);
      }
    }).catch((err) => {
      dispatch(stopLoader());
      if (err.message === 'Unauthorized') {
        navigate('/login');
      }
      toast.warn(err.message);
    });
  };

  // const onChatSelect = (e) => {
  //   dispatch(setSelectedChat(e));
  //   getMessages(e.id);
  //   let socket = new WebSocket(`${BaseUrl.socketIp}chating_conversation/private_chat__${e.personal_room}`);

  //   socket.onopen = function (event) {
  //     console.log("WebSocket connection established conversation.");
  //   };

  //   socket.onmessage = function (event) {
  //     const data = JSON.parse(event.data);
  //     console.log("Received message from server:", data);
  //     dispatch(updateMessages(data));
  //   };

  //   socket.onclose = function (event) {
  //     console.log("WebSocket connection closed.");
  //   };
  // }


  const onChatSelect = (e) => {
    console.log("onchatselect",e)
    dispatch(setSelectedChat(e));
    getMessages(e.id);
  
    // Check if a WebSocket is already initialized for this chat
    let socket = chatSockets[e.personal_room];
  
    if (!socket) {
      // If WebSocket is not initialized, create a new one
      socket = new WebSocket(`${BaseUrl.socketIp}chating_conversation/private_chat__${e.personal_room}`);
  
      socket.onopen = function (event) {
        console.log("WebSocket connection established conversation.");
      };
  
      socket.onmessage = function (event) {
        const data = JSON.parse(event.data);
        console.log("Received message from server:", data);
        dispatch(updateMessages(data));
      };
  
      socket.onclose = function (event) {
        console.log("WebSocket connection closed.");
      };
  
      // Update the state with the new WebSocket object
      setChatSockets((prevChatSockets) => ({
        ...prevChatSockets,
        [e.personal_room]: socket,
      }));
    }
  };

  const sendMessage = (msg) => {
    console.log("sendmessag")
    if (msg.trim() || audio || attachments.length > 0) {
      const formData = new FormData();
      formData.append('receiver', selectedChat.id);
      formData.append('message_type', audio ? 'audio' : (attachments.length > 0 && !imageMimeTypes.includes(attachments[0]?.type) ? 'docs' : (attachments.length > 0 ? 'image' : 'text'))); //docs
      formData.append('message', audio ? 'audio_code=#aud-scopium-956' : (attachments.length > 0 ? 'image_code=#img-scopium-980' : msg));
      // formData.append('message', msg);
      attachments.map((e) => formData.append('file', e));
      formData.append('audio_bytes', audio ? audio : '');
      PostApi(
        `${BaseUrl.baseUrl}chats/send_message/`,
        formData,
        token
      ).then(() => {
        dispatch(shortNotiSound());
        dispatch(setAudio(null));
        dispatch(setAudioUrl(null));
        dispatch(setIsAttachment(false));
        dispatch(setAttachment([]));
        // setTimeout(() => {
        //   lastMessageRef.current.scrollIntoView({ behavior: 'smooth' })
        // }, 100);
      }).catch((err) => {
        if (err.message === 'Unauthorized') {
          navigate('/login');
        }
        toast.error(err.message);
      });
    }
  };


  return (<>
    <div className="content-chat">
      <Sidbar />
      <ChatUserList onChatSelect={onChatSelect} />
      <UserChat sendMessage={sendMessage} />

  
    </div>
  </>
  )
}

export default Chat