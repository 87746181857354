import React, { useEffect, useState } from 'react';
import { BsCardImage, BsFiletypeDoc, BsFillMicFill } from 'react-icons/bs'
// import { AiOutlineLogout } from 'react-icons/ai'
import { BsPersonFillAdd } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { BaseUrl } from '../../../baseurl/BaseUrl';
import { PostApi, getApiWithToken } from '../../Helper/helper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import doctor from "../doctor.png"
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./modalcss.css"
export default function ChatUserList({ onChatSelect }) {
  const navigate = useNavigate();
  const [filteredList, setFilteredList] = useState([]);
  console.log('filteredList',filteredList)

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState([]);
  

  const [loginUser, setloginUser] = useState([]);
  const [show, setShow] = useState(false);
  const [userNames, setUserNames] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { chats, selectedChat } = useSelector((state) => state.chat);
  const [token] = useState(localStorage.getItem("access_token"));

  // const loginUser = JSON.parse(localStorage.getItem('user'));
  

  useEffect(() => {
    getProfile()
    setFilteredList(chats);
    getAllUsers()
  }, [chats])

  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    var updatedList = [...chats];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    // console.log('updatedList', updatedList)
    setFilteredList(updatedList);
  };

  const getProfile = () => {
    // dispatch(startLoader());
    getApiWithToken(
      `${BaseUrl.baseUrl}chats/user/specific_user_detail/`,
      "",
      token
    ).then(({ data }) => {
      //   dispatch(stopLoader());
      const Data = data.data;
      if (data.status) {
        console.log("Data profile===", Data.profile)
        setloginUser(Data)
        setFname(Data.first_name)
        setLname(Data.last_name)
        setProfile([Data.profile])
        // dispatch(setMessages(Data));
      } else {
        toast.warn(Data.message);
      }
    }).catch((err) => {
      //   dispatch(stopLoader());
      if (err.message === 'Unauthorized') {
        navigate('/login');
      }
      toast.warn(err.message);
    });
  };


  const getAllUsers = () => {
    // dispatch(startLoader());
    getApiWithToken(
      `${BaseUrl.baseUrl}chats/user/all_users/`,
      "",
      token
    ).then(({ data }) => {
      //   dispatch(stopLoader());
      const Data = data.data;
      if (data.status) {
        console.log("All users ", Data)
        setUserNames(Data)

      } else {
        toast.warn(Data.message);
      }
    }).catch((err) => {
      //   dispatch(stopLoader());
      if (err.message === 'Unauthorized') {
        navigate('/login');
      }
      toast.warn(err.message);
    });
  };


  const sendMessage = (userid) => {
    const formData = new FormData();
    formData.append('id', userid);
    PostApi(
      `${BaseUrl.baseUrl}chats/user/create_room/`,
      formData,
      token
    ).then((response) => {
      // Check if the response has the expected structure
      if (response?.data?.status === true) {

        toast.warn(response?.data?.message)

        // Assuming response.data is a string, you can use it accordingly
        // If response.data is an object, you can access its properties similarly
        // For example, if it's an object: console.log(response.data.propertyName);
        handleClose();
        getProfile();
      } else {
        console.error("Invalid response structure:", response.data);
        // Handle the case where the response structure is not as expected
      }
    }).catch((err) => {
      if (err.message === 'Unauthorized') {
        navigate('/login');
      }
      toast.error(err.message);
    });
  };



  return (
    <>


      <div className="content-chat-user tabletscreen" >
        <div className="head-search-chat smllmargin">
          <div className="head-user">
            <div className="avatar">
              {profile.map((v, i) => {
                console.log("check profile use list", v); // Isse v ke andar ke data ko console par dekh sakte hain
                return <img
                key={i}
                  src={BaseUrl.imgUrl + v?.profile_pic}
                  //  src={doctor}
                  alt="profile"
                  loading='lazy'
                />
              })}
              <div className="online"></div>
            </div>
            <div className="message-user-profile">
              <strong className="mt-0 mb-0 text-capitalize">
                {}
                {loginUser?.first_name === undefined ? "waiting...": loginUser?.first_name + ' ' + loginUser?.last_name}
                {/* safdar ali khan */}
              </strong>
              <br />
              <small className="mt-0 mb-0">
                {loginUser?.email}
                {/* safdar@gmail.com */}
              </small>
            </div>
            {/* </> :
            <h4 className='w-100 text-center text-white'>Get Started Chat</h4>
          } */}
          </div>
          <div className="logout" onClick={handleShow}>
            {/* <AiOutlineLogout size={30} /> */}
            <BsPersonFillAdd size={30} />

          </div>
          {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
            Launch 
          </button> */}
          {/* <h4 className="text-center">Chats</h4> */}
        </div>
        <div className="search-user mt-20">
          <input
            type="text"
            placeholder="Search..."
            name="search"
            className="search"
            onChange={filterBySearch}
          />
        </div>
        <div className="list-search-user-chat mt-20">
          {filteredList.length > 0 ?
            filteredList.map((e, i) => (
              <div className={selectedChat?.id === e.id ? "user-chat active" : "user-chat"} data-username={e.full_name} key={e.id} onClick={() => onChatSelect(e)}>

                <div className="user-chat-img">
                  <img
                    src={BaseUrl.imgUrl + e?.profile}
                    alt=""
                    lazy='loading'
                  />
                  <div className={i % 2 === 0 ? "online" : "offline"} />
                </div>
                <div className="user-chat-text">
                  <p className="username mt-0 mb-0">
                    <strong>{e?.full_name}</strong>
                  </p>
                  <MessageType message={e} />
                </div>
              </div>
            ))
            :
            <h4>User not found</h4>
          }
        </div>



        <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
          <Modal.Header closeButton>
            <Modal.Title>All Users</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='content-chat-user '>

              <div className="list-search-user-chat mt-20 ">
                {userNames.length > 0 ?
                  userNames.map((e, i) => {
                    console.log("check modal profile",e?.profile?.profile_pic); // Isse e ke andar ke data ko console par dekh sakte hain
                    return (
                      <div className={selectedChat?.id === e.id ? "user-chat active" : "user-chat"} data-username={e.full_name} key={e.id} onClick={() => sendMessage(e.id)}>

                        <div className="user-chat-img">
                          <img
                            src={e?.profile?.profile_pic ? BaseUrl?.imgUrl + e?.profile?.profile_pic : doctor }
                            alt=""
                            lazy='loading'
                          />

                          <div className="online" />
                        </div> 
                        <div className="user-chat-text">
                          <p className="username mt-0 mb-0">
                            <strong>{e?.first_name + ' ' + e?.last_name}</strong>
                          </p>

                        </div>
                      </div>
                    );
                  })
                  :
                  <h4>User not found</h4>
                }
              </div>
            </div>


          </Modal.Body>

        </Modal>
      </div>
    </>

  )
}

export const MessageType = ({ message }) => {
  // const audioContext = new AudioContext();
  // function getAudioLength(audioData) {
  //   return new Promise((resolve, reject) => {
  //     audioContext.decodeAudioData(audioData.buffer, (decodedData) => {
  //       // Resolve with the duration of the audio in seconds
  //       resolve(decodedData.duration);
  //     }, (error) => {
  //       reject(error);
  //     });
  //   });
  // }

  // const VoiceDuration = (audioDataURL) => {
  //   console.log('audioDataURL',audioDataURL)
  //   getAudioLength(audioDataURL).then((duration) => {
  //     console.log(`Audio duration: ${duration} seconds`);
  //   }).catch((error) => {
  //     console.error('Error:', error);
  //   });
  // }


  let msg = null;
  if (message.message_type === 'image') {
    msg = (<>
      <BsCardImage color='grey' />
      <small> image</small>
    </>)
  } else if (message.message_type === 'docs') {
    msg = (<>
      <BsFiletypeDoc size={18} color='grey' />
      <small> Docs</small>
    </>)
  } else if (message.message_type === 'audio') {
    msg = (<>
      <BsFillMicFill color='#03c524' />
      <small> voice note</small>
    </>)
  } else {
    msg = (
      <small title={message.last_message}>{message.last_message.length > 20 ? message.last_message.slice(0, 20) + '...' : message.last_message}</small>
    )
  }

  return msg;
}